import { LitElement, html, css } from "lit";
import "@lion/ui/define/lion-option.js";
import { LocationCombobox } from "./location-combobox.js";
import {lazyRender} from "./lazyRender.js";

import { regions } from "./shared/regions.js";

import { normalize, openprops } from "./shared/openprops.css.js";


class MyLocationSearch extends LitElement {

  constructor() {
    super();
    this.items = {}
    this.regionsArray = regions.map(region => region[1]);
    this.selectedLocation = {};
  }

  static get properties() {
    return {
      items: {
        type: Array,
      },
      label: {
        type: String,
      },
      modelValue: {
        type: String,
      },
      selectedLocation: {
        type: Object,
      }
    }
  }

  static get styles() {
    return css`
      ${normalize}
      ${openprops}
      
      location-combobox {
        appearance: none;
        width: var(--size-13);
      }

      location-combobox > lion-options:has(lion-option) {
        border-radius: var(--radius-2) !important;;
        padding: var(--size-2);
      }

      location-combobox > lion-options > i {
        color: var(--gray-6);

      label {
        color: var(--label-color);
        display: var(--label-display-type);
      }


    `;
  }

  render() {
    return html`
      <location-combobox label="${this.label}" id="combobox" .modelValue=${this.modelValue} @input=${this._registerChange} .requireOptionMatch="${false}" autocomplete="none">
      ${this.items.cities?.length > 0  ? html`<b>Plaatsen:</b><br>` : ``}
      ${this.items.cities?.length > 0  ? lazyRender(this.items.cities.map((location, i) => html`<lion-option @click=${this._clickOption} data-location='{"type": "city", "city": "${location.city}", "region": "${location.region}"}' .choiceValue="${location.city}">${location.city}</lion-option>`)) : ``}
     
      ${this.items.regions?.length > 0  ? html` <b>Regios:</b><br>` : ``}
      ${this.items.regions?.length > 0  ? lazyRender(this.items.regions.map((region, i) => html`<lion-option @click=${this._clickOption} data-location='{"type": "region", "city": "", "region": "${region}"}' .choiceValue="${region}">${region}</lion-option>`)) : ``}
      ${this.modelValue  && this.items.regions?.length === 0 && this.items.cities?.length === 0  ? this._renderNoResultsFromLocationSearch()  : ``}
      ${this.modelValue ? lazyRender(html`<i>Je kunt ook zoeken in de<br>omschrijvingen van de huizen:</i><lion-option @click=${this._clickOption} .choiceValue="${this.modelValue}">${this.modelValue}</lion-option>`) : ``}
      </location-combobox>
    `;
  }

  _renderNoResultsFromLocationSearch() {
    return lazyRender(html`Geen locaties gevonden<br><b>Zoek op iets anders</b><br>`);
  }

  _clickOption = (event) => {
    this.modelValue = event.currentTarget.choiceValue;
    if(event.currentTarget.dataset.location) {
      this.selectedLocation = JSON.parse(event.currentTarget.dataset.location);
    }
  }

  _registerChange = async (event) => {
    this.selectedLocation = {};
    let cities = [];
    let matchingRegions = [];
    const searchString = event.target.value;
    this.modelValue = searchString;
    const url = `/api/houses/location/${searchString}`;

    if(searchString.length > 0) {
      matchingRegions = this.regionsArray.filter(element => element.toLowerCase().startsWith(searchString.toLowerCase()));
      cities = await fetch(url)
      .then((res) => res.json())
      .then((data) => {
        return data.locations;
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e));
    } else {
      cities = [];
      matchingRegions = [];
    }
      
      this.items = { cities: cities, regions: matchingRegions}
  }
}

customElements.define("my-location-search", MyLocationSearch);
