import { LitElement, html, css } from "lit";

import { regions } from "./shared/regions.js";
import { persons } from "./shared/persons_with_text.js";
import { normalize, openprops, buttons } from "./shared/openprops.css.js";

import "./my-select";
import "./my-location-search";

// TODO als eerste datum is gekozen 2e kalender ook op die datum zetten

class SearchForm extends LitElement {
  static get styles() {
    // language=CSS
    return css`
      ${openprops}
      ${normalize}
      ${buttons}
      .search {
        display: flex;
        flex-flow: row wrap;
        background: var(--gray-1);
        border-radius: var(--radius-2);
        --label-color: var(--gray-7);
        box-shadow: var(--shadow-4);
        width: calc(var(--size-14) * 3.2 );
        height: var(--size-10);
        padding-top: var(--size-3);
        justify-content: center;
      }

      .search h2 {
        font-size: var(--font-size-5);
        width: 100%;
        color: var(--primary-text-color);
        text-align: center;
        padding-top: var(--size-3);
      }

      #togglecalendarsbuttons button {
        margin-left: var(--size-2);
      }

      .searchbutton {
        color: #fff;
        background-color: var(--secondary-color);
        margin-left: var(--size-2);
      }

      date-input {
        width: var(--size-12);
        height: var(--size-9);
        margin-inline: var(--size-2);
        display: none;
        --label-display-type: none;
      }

      my-select, my-location-search {
        --label-display-type: none;
        margin-left: var(--size-2);
      }

      .spinner {
        display: flex;
        height: 60%;
        width: 100%;
        align-items: center;
        justify-content: center;
      }

      @media screen and (max-width: 414px) {
        my-select, my-location-search {
          --label-display-type: block;
          --label-color: var(--primary-text-color);
          width: var(--size-13);
          height: var(--size-9);
          margin-inline: var(--size-3);
        }

        date-input {
          --label-display-type: block;
          width: var(--size-13);
        }
        
        .buttonrow {
          width: var(--size-13);
          padding-top: var(--size-1);
        }

        #togglecalendarsbuttons button {
          width: 100%;
          margin: 0;
        }

        .search {
          width: var(--size-14);
          height: calc(var(--size-11) + var(--size-12));
        }

        .search.with-calendars {
          height: calc(var(--size-14) + var(--size-9));

        }

        .search h2 {
          font-size: var(--font-size-3);
          height: var(--size-8);
        }

        .searchbutton {
          width: 100%;
          margin: 0;
        }
      }

    `;
  }

  async firstUpdated() {
    this.hydrated = true;
    setTimeout(() => {
      this.personen = this.persons;
      this.region = this.region ? this.region : "Alle regios";
    });
  }

  render() {
    // language=HTML
    return html`
      <div class="search">
        ${this.hydrated
          ? html` 
              <my-location-search label="Bestemming of zoekterm" id="location"></my-location-search>
              <my-select
                id="persons"
                .items=${persons}
                name="Personen"
                label="Personen"
                .modelValue=${this.personen}
              >
              </my-select>
              <div class="buttonrow"  id="togglecalendarsbuttons">
              <button @click="${this._togglecalendars}">
                Data opgeven
              </button>
            </div>
              <date-input
                id="aankomst"
                label="Aankomst"
                .minDate="${new Date().toLocaleDateString("nl-NL")}"
                value="${new Date().toLocaleDateString("nl-NL")}"
              ></date-input>
              <date-input
                id="vertrek"
                label="Vertrek"
                .min-date="${new Date(
                  new Date().setDate(new Date().getDate() + 1)
                ).toLocaleDateString("nl-NL")}"
                value="${new Date(
                  new Date().setDate(new Date().getDate() + 7)
                ).toLocaleDateString("nl-NL")}"
              ></date-input>
              <div class="buttonrow">
                <button class="searchbutton" @click="${this._search}">
                  Zoeken
                </button>
              </div>`
          : html`<div class="spinner">
              <img src="/images/tail-spin.svg" />
            </div>`}
      </div>
    `;
  }

  static get properties() {
    return {
      aankomst: String,
      vertrek: String,
      personen: Number,
      hydrated: Boolean,
      region: String,
      persons: String,
    };
  }

  constructor() {
    super();
    this.hydrated = false;
  }
  
  async _togglecalendars() {
    await import("./date-input.js");
    this.shadowRoot.querySelector('.search').classList.add('with-calendars');
    this.shadowRoot.querySelectorAll('date-input').forEach(el => el.style.display = "block");
    this.shadowRoot.getElementById('togglecalendarsbuttons').style.display = "none";
  }

  _search() {
    // todo do we have to prevent double clicking?
    const country = this.country ? this.country : "nederland";
    let selectedLocation = this.shadowRoot.getElementById("location").selectedLocation;

    let locationInUrl = selectedLocation.region ? selectedLocation.region : 'alleregios';
    locationInUrl = selectedLocation.type === 'city' ? `${locationInUrl}_${selectedLocation.city}` : locationInUrl;
    let url = `/vakantiehuis/${country}/${locationInUrl}/pagina/1?personen=${this.shadowRoot.querySelector("#persons").modelValue}&sortvolgorde=desc&sorteertype=hartjes`;
    if (this.shadowRoot.getElementById("aankomst").value) {
      url += `&aankomst=${this.shadowRoot.getElementById("aankomst").value}&vertrek=${this.shadowRoot.querySelector("#vertrek").value}`;
    }
    if (this.shadowRoot.getElementById("location").modelValue && Object.keys(selectedLocation).length === 0) { 
      // no specific location searched, get the searchterm if any
      url += `&searchterm=${this.shadowRoot.getElementById("location").modelValue}`
      }
    window.location.href = url;
  }
}

globalThis.customElements.define("search-form", SearchForm);
