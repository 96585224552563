import { css } from 'lit';
import { LionCombobox } from '@lion/ui/combobox.js';
import { normalize, openprops, buttons } from "./shared/openprops.css.js";


export class LocationCombobox extends LionCombobox {
  
  static get properties() {
    return {
      modelValue: {
        type: String,
      },
      label: {
        type: String,
      },
    }
  }

  static get styles() {
    return [
      ...super.styles,
      css`
      ${openprops}
      ${normalize}
      ${buttons}
        /** @configure LionCombobox */

        .input-group__container {
          display: flex;
          border-bottom: none;
        }
        ::slotted([slot='input']) {
          padding-block: 0.75ch !important;
          height: inherit;
          border-width: var(--border-size-2);
          border-radius: var(--radius-2) 0 0 var(--radius-2) !important;;
          font-size: var(--font-size-1);
          width: 100%;
          padding-inline: 1.25ch 0 !important;;
        }

        * > ::slotted([role='listbox']) {
        display: block;
        overflow: auto;
        z-index: 1;
        background: white;
      }
      * > ::slotted(label) {
        color: var(--label-color);
        display: var(--label-display-type);
      }

      `,
    ];
  }

  constructor() {
    super();
    this.labelValue = "Heel Nederland";
  }

  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    this._inputNode.value = this.modelValue ? this.modelValue : this.labelValue;
    this._inputNode.addEventListener("focus", () => {
      this._inputNode.value = this.modelValue ? this.modelValue : '';
    });
    this._inputNode.addEventListener("blur", () => {
      this._inputNode.value = this.modelValue === '' ? this.labelValue : this.modelValue;
    });
  }

  /**
     * Wrapper with combobox role for the text input that the end user controls the listbox with.
     * @type {HTMLElement}
     */
  get _comboboxNode() {
    if (this.__comboboxNode) {
      return this.__comboboxNode;
    }
    const slot = this.querySelector('[slot="input"]');
    if (slot) {
      this.__comboboxNode = slot;
      return slot;
    }
    const slot2 = this._overlayCtrl?.contentWrapperNode.querySelector('[slot="input"]');
    if (slot2) {
      this.__comboboxNode = slot2;
      return slot2;
    }
    return null;
  }
}
customElements.define('location-combobox', LocationCombobox);